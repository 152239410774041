import React from 'react'
import './App.css'
import { Nav } from './components/Nav'
import { Main } from "./components/Main";
import {Dialog} from "./components/Dialog";
import {Footer} from "./components/Footer";

function App() {
    let [visible, setVisibility] = React.useState('hidden')
    const popUp = () => {
        if (visible === 'hidden') {
            setVisibility('grid')
        }
        else {
            setVisibility('hidden')
        }
    }
  return (
      <body className={"bg-gray-200 font-sans select-none transition"}>
        <Nav/>
        <Dialog visible={visible} popUp={popUp}/>
        <Main setPopup={popUp}/>
        <Footer/>
      </body>
  )
}

export default App
