import React from 'react';
import {Card} from "./card";

interface Props {
    setPopup: () => void
}

export const Main = (props: Props) => {
    return (
        <main className={''}>
            <article
                id="home"
                className="grid grid-cols-4 grid-rows-2 h-auto md:h-full overflow-hidden">
                <div className="flex flex-col gap-4 my-auto p-4
                    col-span-4 col-start-1
                    md:col-span-2 md:col-start-2
                    row-start-1
                    z-40">
                    <h2 className="text-3xl">Hello</h2>
                    <h1 className="text-5xl font-bold">
                        <span>I'm</span>
                        <span className="text-red-600">Marcos</span>
                    </h1>
                    <p>Programmer, Web Developer, Data Scientist, Quality Management</p>
                    <p>located in Switzerland looking for work in IT</p>
                    <button className="text-white hover:bg-red-600 bg-red-400 p-4 w-max flex cursor-pointer"
                       onClick={() => props.setPopup()}>
                        <p id="popup">How good do you know me ?</p>
                        <span className="relative -right-10 -top-6 h-4 w-4 animate-ping rounded-full bg-blue-600 opacity-90"/>
                        <span className="relative -right-6 -top-6 h-4 w-4 rounded-full bg-blue-600 opacity-90"/>
                    </button>
                </div>
                <div id="about"
                     className="flex flex-col gap-4 my-auto p-4
                    col-span-4 col-start-1
                    md:col-span-2 md:col-start-2
                    row-start-2
                    z-40">
                    <h1 className="text-5xl font-bold">
                        <span>About</span>
                        <span className="text-blue-600">Me</span>
                    </h1>
                    <p>I'm 27 and a Student undergraduate Computer Scientist</p>
                    <p>I like biking and programming</p>
                    <p>I'm always eager to learn more</p>
                </div>
                <div className="mt-auto col-start-3 row-start-1 col-span-2 row-span-2 z-1">
                    <img className={"z-1"}
                         src={"marcos-gamez.webp"}
                         alt={"marcos gamez"}
                         loading="lazy"/>
                </div>
            </article>
            <article className="bg-gray-300 flex flex-col pt-20">
                <section id="services"
                         className="flex flex-col gap-4 p-4 my-auto space-4 h-full
                         sm:w-1/2 w-full m-auto">
                    <h1 className="text-5xl font-bold">
                        <span className="text-red-600">My</span>
                        <span>Services</span>

                    </h1>
                    <div className="grid grid-cols-2 gap-4 text-center">
                        <div className="flex flex-col text-white bg-red-400 shadow p-4">
                            <p className="font-mono text-3xl tracking-widest font-bold">
                                &lt;HTML&gt;
                            </p>
                            <p className="col-span-2">Software Development</p>
                        </div>
                        <div className="flex flex-col text-white bg-red-400 shadow p-4">
                            <p className="font-mono text-3xl tracking-widest font-bold">
                                &lt;HTML&gt;
                            </p>
                            <p className="col-span-2">Dev Ops</p>
                        </div>
                        <div className="flex flex-col text-white bg-red-400 shadow p-4">
                            <p className="font-mono text-3xl tracking-widest font-bold">
                                f(x)
                            </p>
                            <p className="col-span-2">Data Science</p>
                        </div>
                        <div className="flex flex-col text-white bg-red-400 shadow p-4">
                            <p className="font-mono text-3xl tracking-widest font-bold">
                                &lt;QM&gt;
                            </p>
                            <p className="col-span-2">Quality Management</p>
                        </div>
                    </div>
                </section>
                <section className="flex flex-col gap-8 p-4 sm:w-1/2 w-full m-auto pt-20">
                    <h1 id="portfolio"
                        className="text-5xl font-bold">
                        <span className="text-blue-600">My</span>
                        <span>Portfolio</span>
                    </h1>
                    <div className="flex flex-col gap-20">
                        <Card
                            name={"Johdoon"}
                            Img={"https://johdoon.ch/favicon.ico"}
                            Link={"https://johdoon.ch"}
                            linkName={"Johdoon.ch"}
                            description={`
                                Johdoon offer you a Switzerland-wide Contact 
                                point for sustainable products. Discover in 
                                the online shop our selection of sustainable 
                                products.
                            `}
                            tech={[
                                "Typescript",
                                "Javascipt",
                                "Python",
                                "ReactJS",
                                "Tailwind CSS",
                                "Postgres",
                                "Docker",
                                "Google Cloud",
                            ]}
                        />
                        <Card
                            name={"TwentyEightDays"}
                            Img={"https://github.com/twenty-eight-days/TwentyEightDaysNative/raw/main/src/view/ressources/today.png"}
                            Link={"https://github.com/twenty-eight-days/TwentyEightDaysNative"}
                            linkName={"TwentyEightDays"}
                            description={`
                                Privacy-First Cycle Tracking App (React Native, iOS/Android).
                            `}
                            tech={[
                                "Android",
                                "Typescript",
                                "ReactNative",
                            ]}
                        />
                        <Card
                            name={"JürgenAlesi"}
                            Img={"https://jürgenalesi.ch/logo192.png"}
                            Link={"https://jürgenalesi.ch"}
                            linkName={"Alesi"}
                            description={`
                                I am juergen a painter from thun
                                and this is my personal website
                            `}
                            tech={[
                                "Typescript",
                                "ReactJS",
                                "Tailwind CSS",
                                "Docker",
                            ]}
                        />
                        <Card
                            name={"Compayer"}
                            Img={"https://compayer.ch/static/img/QR.svg"}
                            Link={"https://compayer.ch"}
                            linkName={"Compayer"}
                            description={`
                                simply create swiss qr bills with compayer
                            `}
                            tech={[
                                "Python",
                                "Javascript",
                                "Django",
                                "ReactJS",
                                "Postgres",
                                "Docker",
                            ]}
                        />
                        <Card
                            name={"Pyogame"}
                            Img={"https://github.com/alaingilbert/pyogame/blob/develop/logo.png?raw=true"}
                            Link={"https://github.com/alaingilbert/pyogame"}
                            linkName={"Pyogame"}
                            description={`
                                open source library for users to create and manage AI
                                to play ogame
                            `}
                            tech={[
                                "Python",
                                "Javascript",
                                "Django",
                                "Flask",
                                "Postgres",
                                "Redis",
                                "Docker",
                            ]}
                        />
                        <Card
                            name={"Measure App"}
                            Img={"mapp.png"}
                            Link={"Demo.mp4"}
                            linkName={"Video"}
                            description={`
                                measure medical or terrestial pictures
                            `}
                            tech={[
                                "Java",
                                "Kotlin",
                                "JavaFX",
                                "Maven",
                                "Gitlab CI/CD",
                            ]}
                        />
                        <Card
                            name={"Roomba S-O-I"}
                            Img={"https://github.com/PiecePaperCode/Roomba-Scheduler-Open-Interface/raw/master/Pictures/Instruction_1.png"}
                            Link={"https://github.com/PiecePaperCode/Roomba-Scheduler-Open-Interface"}
                            linkName={"Roomba Scheduler"}
                            description={`
                                Roomba-Scheduler-Open-Interface brings easy 
                                access to users who desire to run a
                                Schedule Script on their Roomba.
                            `}
                            tech={[
                                ".NET",
                                "Visual Basic",
                                "Windows Forms",
                                "Hardware Programming",
                                "Roomba Robot",
                            ]}
                        />
                    </div>
                </section>
            </article>
            <article className="grid grid-cols-4 gap-4 pt-20">
                <section className="flex flex-col gap-4 p-4 space-4
                            col-span-4 col-start-1 h-full
                            md:col-span-2 md:col-start-2
                            row-start-1">
                    <h1 id="services"
                        className="text-5xl font-bold">
                        <span className="text-red-600">My</span>
                        <span id="resume">Resume</span>
                    </h1>
                    <h2 className="text-2xl font-bold">Experience</h2>
                    <div className="grid grid-cols-3 gap-4">
                        <p className="font-bold grid auto-rows-min">
                            <span>today</span>
                            <span>Sep 2021</span>
                        </p>
                        <p className="col-span-2 flex flex-col">
                            <span className="text-red-600 font-bold">Software Developer</span>
                            <span>e Commerce, Johdoon GmbH, Basel</span>
                        </p>
                        <p className="font-bold grid auto-rows-min">
                            <span>today</span>
                            <span>Apr 2022</span>
                        </p>
                        <p className="col-span-2 flex flex-col">
                            <span className="text-red-600 font-bold">Software Developer</span>
                            <span>Medical Software, University Hospital, Basel</span>
                        </p>
                        <p className="font-bold grid auto-rows-min">
                            <span>Apr 2022</span>
                            <span>Jul 2018</span>
                        </p>
                        <p className="col-span-2 flex flex-col">
                            <span className="text-red-600 font-bold">Quality Specialist</span>
                            <span>Oversight End Products, CSL Behring, Bern</span>
                        </p>
                        <p className="font-bold grid auto-rows-min">
                            <span>Jun 2018</span>
                            <span>Feb 2018</span>
                        </p>
                        <p className="col-span-2 flex flex-col">
                            <span className="text-red-600 font-bold">Service</span>
                            <span>Lindner Hotels, Interlaken</span>
                        </p>
                        <p className="font-bold grid auto-rows-min">
                            <span>Oct 2017</span>
                            <span>Sep 2017</span>
                        </p>
                        <p className="col-span-2 flex flex-col">
                            <span className="text-red-600 font-bold">Internship</span>
                            <span>FHNW, Muttenz</span>
                        </p>
                        <p className="font-bold grid auto-rows-min">
                            <span>Feb 2016</span>
                            <span>Jul 2015</span>
                        </p>
                        <p className="col-span-2 flex flex-col">
                            <span className="text-red-600 font-bold">Ground-Handler</span>
                            <span>AAS, Flughafen Zürich</span>
                        </p>
                    </div>
                    <h2 className="text-2xl font-bold">Education</h2>
                    <div className="grid grid-cols-3 md:gap-4 gap-4">
                        <p className="font-bold grid auto-rows-min">
                            <span>Sep 2023</span>
                            <span>Sep 2019</span>
                        </p>
                        <p className="col-span-2 flex flex-col">
                            <span
                                className="text-red-600 font-bold h-min">Bachelor Life Sciences, Medical Informatics</span>
                            <span className="h-min">FHNW, Muttenz</span>
                        </p>
                        <p className="font-bold grid auto-rows-min">
                            <span>Feb 2018</span>
                            <span>Sep 2014</span>
                        </p>
                        <p className="col-span-2 flex flex-col">
                            <span className="text-red-600 font-bold">Bachelor Life Sciences, Chemistry</span>
                            <span>FHNW, Muttenz</span>
                        </p>
                        <p className="font-bold grid auto-rows-min">
                            <span>Aug 2014</span>
                            <span>Aug 2013</span>
                        </p>
                        <p className="col-span-2 flex flex-col">
                            <span className="text-red-600 font-bold">Technische Berufsmatur</span>
                            <span>GIB Thun, Thun</span>
                        </p>
                        <p className="font-bold grid auto-rows-min">
                            <span>Aug 2013</span>
                            <span>Aug 2010</span>
                        </p>
                        <p className="col-span-2 grid grid-rows-2">
                            <span className="text-red-600 font-bold">Chemie & Pharmatechnologe EFZ</span>
                            <span>Novartis, Muttenz</span>
                        </p>
                    </div>
                </section>
            </article>
        </main>
    )
}