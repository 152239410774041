import React from 'react';

export const Nav = () => {
    return (
        <nav className="flex p-5 bg-gray-100 sm:bg-gray-100 h-auto md:h-20">
            <div className="text-4xl font-bold m-auto hidden md:block">
                <span className="text-red-600">Gamez</span>
            </div>
            <section className="flex flex-wrap gap-4 m-auto ">
                <a className="tracking-widest" href="#home">HOME</a>
                <a className="tracking-widest" href="#about">ABOUT</a>
                <a className="tracking-widest" href="#services">SERVICE</a>
                <a className="tracking-widest" href="#portfolio">PORTFOLIO</a>
                <a className="tracking- widest" href="#resume">RESUME</a>
            </section>
        </nav>
    )
}