import React from "react";

export const Card = (
    props: {
        name:string,
        Img:string,
        Link:string,
        linkName:string,
        description:string,
        tech:string[]
    }
) => {
    return (
        <a href={props.Link} className="flex flex-col text-white bg-blue-400 shadow-2xl p-12 gap-12">
            <div className={"flex border-b-2 pb-4 border-blue-500"}>
                <p className="text-4xl col-span-2 my-auto
                              tracking-wider font-bold">
                    {props.name}
                </p>
                <div className="ml-auto bg-white flex rounded-full h-20 w-20">
                    <img className={"m-auto h-15 h-15 rounded-full"}
                         src={props.Img}
                         alt={props.linkName}/>
                </div>
            </div>
            <p className="col-span-3">
                {props.description}
            </p>
            <div className={"flex flex-wrap gap-4"}>
                {props.tech.map(tech=><p className={"rounded shadow bg-blue-900 p-1 my-automy-auto"}>{tech}</p>)}
            </div>
        </a>
    )
}