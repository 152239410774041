import React from 'react';

interface Props {
    question: string
    answer1: [string, boolean]
    answer2: [string, boolean]
    answer3: [string, boolean]
    num: string
}

export const Questions = (props: Props) => {
    let [answer, setAnswer] = React.useState('')
    let [answerColor, setAnswerColor] = React.useState('')

    function answertheQuestion(ans: [string, boolean]) {
        if (ans[1]) {
            setAnswer("Your are Right")
            setAnswerColor("text-green-400")
        }
        else {
            setAnswer("Your are Wrong")
            setAnswerColor("text-red-400")
        }
    }

    return (
        <fieldset className="flex flex-col">
            <label className="grid grid-cols-2 place-items-start">
                <span className="pl-0 pt-4 pb-4">{ props.question }</span>
                <span className={`pl-0 pt-4 pb-4 ${answerColor}`}>{ answer }</span>
            </label>
            <label onClick={ () => answertheQuestion(props.answer1) }>
                <input type="radio" name={props.num}/>
                <span>{ props.answer1[0] }</span>
            </label>
            <label onClick={ () => answertheQuestion(props.answer2) }>
                <input type="radio" name={props.num}/>
                <span>{ props.answer2[0] }</span>
            </label>
            <label onClick={ () => answertheQuestion(props.answer3) }>
                <input type="radio" name={props.num}/>
                <span>{ props.answer3[0] }</span>
            </label>
        </fieldset>
    )
}
