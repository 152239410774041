import React from 'react'
import {Questions} from "./Questions";


interface Props {
    visible: string,
    popUp: () => void
}

export const Dialog = (props: Props) => {
    return (
        <div className={`dialog ${props.visible}`} id="dialog_bg">
            <article id="questions" className="bg-white p-10 shadow rounded flex flex-col gap-4">
                <h1 className="text-5xl font-bold">How Good do you know me</h1>
                <h2 className="text-2xl">Answer the quetions?</h2>
                <Questions question={"I am ... years old?"}
                           answer1={["27", false]}
                           answer2={["28", false]}
                           answer3={["29", true]}
                           num={"1"}
                />
                <Questions question={"In my freetime i like to ...?"}
                           answer1={["hike", false]}
                           answer2={["bike", true]}
                           answer3={["swim", false]}
                           num={"2"}
                />
                <Questions question={"What was my profession before IT?"}
                           answer1={["construction", false]}
                           answer2={["chemestry", true]}
                           answer3={["sales", false]}
                           num={"3"}
                />
                <p id="exitPopUp"
                   className="text-white hover:bg-red-600 bg-red-400 p-4 w-max flex cursor-pointer"
                   onClick={props.popUp}>
                    Close
                </p>
            </article>
        </div>
    )
}
