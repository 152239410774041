import React from 'react';

export const Footer = () => {
    return (
        <footer className={"flex bg-gray-100"}>
            <a
                href={"mailto:marcos.gamez@johdoon.ch"} className={"p-4 m-auto"}>
                marcos.gamez@johdoon.ch
            </a>
        </footer>
    )
}